import React from "react";

import '../assets/styles/Book.css'

import { InlineWidget } from "react-calendly";

import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'

export default function Contact() {
    return (
        <main>
            <img id='contact-section-divider' src={VocalDivider600} alt="" />
            <h2 id='confirmation-text'>Your submission was successful.</h2>
            <h2 id='thank-you-text'>Thank you for contacting <span id='booking-page-rarevox-highlight'>RareVox Consulting</span>.</h2>
            <h2 id='booking-instruction-text'>If you would like to also book an introductory meeting, please proceed below.</h2>
            <InlineWidget class="calendly-inline-widget" url="https://calendly.com/rarevox/introductory-meeting?background_color=fff&primary_color=ff6600"/>
        </main>
    )
}