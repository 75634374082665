import React from 'react'
import { Link } from 'react-router-dom'

import '../assets/styles/Footer.css'

import Logo from '../assets/graphics/logo-orange.png'

const Footer = () => {
  return (
    <footer>
        <div id='footer-content-container'>
            <nav id='footer-nav'>
                <ul id='services-nav'>
                    <li className='footer-nav-title'>Services</li>
                    <li><Link to="/strategicconsultancy" className='footer-nav-subtitle'>Strategic Consultancy</Link></li>
                    <li><Link to="/operationalsupport" className='footer-nav-subtitle'>Operational Support</Link></li>
                </ul>
                <p><Link to="/about" className='footer-nav-title'>About</Link></p>
                <p><Link to="/cases" className='footer-nav-title'>Cases</Link></p>
                <p><Link to="/blog" className='footer-nav-title'>Blog</Link></p>
            </nav>
            <div id="footer-logo-container">
                <img id='footer-logo' src={Logo} alt="logo" />
            </div>
        </div>
        <div id='footer-copyright-container'>
            <hr id='footer-divider'/>
            <p id='copyright-notice'>© 2024 RareVox Consulting LLC | All Rights Reserved | Developed by <a id='inexora-link'href='https://inexora-ventures.com/'> Inexora Ventures LLC</a></p>
        </div>
    </footer>
  )
}

export default Footer
