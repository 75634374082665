import React from 'react';
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import '../assets/styles/Cases.css'

import VocalDivider100 from '../assets/graphics/vocal-divider-100.svg'
import VocalDivider400 from '../assets/graphics/vocal-divider-400.svg'
import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'
import WomanHeart from '../assets/graphics/woman-heart.png'
import TranformingPerceptions from '../assets/graphics/transforming-perceptions.png'
import RestoringTrust from '../assets/graphics/restoring-trust.png'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


export default function Cases() {
    return (
        <main>
            <section id='hero-section-strategic-consultancy'>
                <h3 id="hero-title-strategic-consultancy">Cases</h3>
            </section>
            <img className='case-section-divider' src={VocalDivider100} alt="" />
            <section id='accordion-1-container'>
                <Accordion sx={{
                    backgroundColor: 'var(--clr-primary-100)',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: '4rem',
                    '@media screen and (min-width:600px)': {
                        fontSize: 'var(--fs-650)',
                        paddingBottom: '12rem',
                    },
                    }}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon
                                sx={{
                                    color: 'var(--clr-neutral-100)',
                                    ".expandIconWrapper": { color: 'var(--clr-neutral-100)' },
                                    marginLeft: '1rem',
                                    marginRight: '1rem',
                                }}
                            />}
                            aria-controls="panel-content"
                            sx={{
                                color: 'var(--clr-neutral-100)',
                                padding: '3rem'
                            }}
                        >Transforming Perceptions: A Success Story in Parkinson’s Line Extension </AccordionSummary>
                        <AccordionDetails sx={{
                                fontSize: 'var(--fs-300)',
                                '@media screen and (min-width:600px)': {
                                    fontSize: 'var(--fs-600)',
                                    paddingLeft: '8rem',
                                    paddingRight: '8rem',
                                    paddingBottom: '8rem',
                                },
                            }}
                        >
                            <div className='case-header-image-container'>
                                <img className='case-header-image' src={TranformingPerceptions} alt="black box with blurred graphic inside, a radiowave icon leading to a clear box with a value icon inside." />
                            </div>
                            <p><span className='case-section-subheader'>Challenge:</span> A major pharmaceutical company faced a daunting challenge with their Parkinson’s disease line extension. Despite introducing a novel drug delivery method aimed at enhancing bioavailability, the product struggled post-launch. Adoption was slow, and the product was perceived as a mere profit-driven initiative rather than a patient-centric innovation. As a result, the company faced missed financial targets and a tarnished image in the healthcare community.</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='case-section-subheader'>RareInsights™ :</span> Recognizing the need for a patient-focused approach, RareVox partnered with the Parkinson Foundation to gain deeper insights. We facilitated multiple focus groups with patients and caregivers, uncovering the true impact of the new delivery system on their Quality of Life (QOL). This approach unearthed compelling “reasons-to-believe” in the product’s value, directly from those it aimed to benefit.</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='case-section-subheader'>Strategy and Execution:</span> Armed with these insights, we redefined the product’s narrative. The marketing materials and campaign were revamped to emphasize the tangible QOL improvements for patients. This new messaging resonated with both healthcare professionals and patients, aligning the product with genuine care and innovation in Parkinson's treatment.</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='case-section-subheader'>The impact:</span> The shift to a QOL-focused message proved transformative. Within 12 months, the new drug delivery method saw a 75% adoption rate among existing patients. The sales team, re-energized by the impactful messaging, effectively communicated the product's benefits, leading to increased profitability and sustained market share in the face of generic competition. Most importantly, the company reestablished its reputation as a patient-first innovator in the Parkinson’s community.</p>
                        </AccordionDetails>
                </Accordion>
            </section>
            <img id='advocacy-section-divider' src={VocalDivider400} alt="" />
            <section id='accordion-2-container'>
                <Accordion sx={{
                    backgroundColor: 'var(--clr-primary-400)',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: '4rem',
                    '@media screen and (min-width:600px)': {
                        fontSize: 'var(--fs-650)',
                        paddingBottom: '12rem',
                    },
                    }}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon
                                sx={{
                                    color: 'var(--clr-neutral-100)',
                                    ".expandIconWrapper": { color: 'var(--clr-neutral-100)' },
                                    marginLeft: '1rem',
                                }}
                            />}
                            aria-controls="panel-content"
                            sx={{
                                color: 'var(--clr-neutral-100)',
                                padding: '3rem',
                            }}
                        >Niche to Mainstream: Catalyzing Broad Product Demand Through Targeted Patient Group</AccordionSummary>
                        <AccordionDetails sx={{
                                fontSize: 'var(--fs-300)',
                                '@media screen and (min-width:600px)': {
                                    fontSize: 'var(--fs-600)',
                                    paddingLeft: '8rem',
                                    paddingRight: '8rem',
                                    paddingBottom: '8rem',
                                },
                            }}
                        >
                            <div className='case-header-image-container'>
                                <img className='case-header-image' src={WomanHeart} alt="a group of men and women with heart icons on their chest, men with men hearts and women with orange hearts." />
                            </div>
                            <p><span className='case-section-subheader'>Challenge:</span> In the dynamic world of radiopharmaceuticals, companies often face the complex challenge of introducing next-generation products while managing the lifecycle of their existing, widely-used offerings. This scenario becomes particularly intricate when the new product, despite its advanced features and technical superiority, carries a significantly higher price tag and lacks comprehensive health economics data. </p>
                            <p>Such was the case for a leading radiopharma company grappling with the task of cannibalizing its own popular product to ensure the successful launch and market penetration of a new, more sensitive and specific imaging agent.Despite the technical advancements, the new agent's adoption faced skepticism from healthcare providers raising concerns about the justification of the cost differential without solid health economics data. This skepticism threatened to stagnate market growth, with projections indicating a potential plateau at a mere 20-30% market share, far below the targeted 80% in the fifth year post-launch. The company was at a crucial juncture and risked to see the ROI fall short of the business case</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='italic'>This campaign was not just a marketing strategy; it was a movement.</span> It challenged the status quo, bringing to light the disparities in cardiac care for women and highlighting how the new imaging product could play a crucial role in changing this narrative. The campaign was meticulously tested in Canada, garnering significant positive feedback, before its high-profile launch in the United States, backed by the endorsement of the American Heart Association and several key opinion leaders.</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='case-section-subheader'>The impact</span> was immediate and profound. Healthcare professionals and administrators, who had previously been hesitant due to the lack of HEOR data, were now seeing the product in a new light. The product sales increased by double digits quarter over quarter and tracked at 80% market share within 18 months - ahead of the 5-year goal.'The Difference in a Woman’s Heart' did more than just promote a product; it sparked a conversation about women's cardiac health, demonstrating the value and necessity of the product in a way that technical data alone could not.</p>
                        </AccordionDetails>
                </Accordion>
            </section>
            <img id='strategic-alliance-section-divider' src={VocalDivider600} alt="" />
            <section id='accordion-2-container'>
                <Accordion sx={{
                    backgroundColor: 'var(--clr-primary-600)',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: '4rem',
                    '@media screen and (min-width:600px)': {
                        fontSize: 'var(--fs-650)',
                        paddingBottom: '6rem',
                    },
                    }}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon
                                sx={{
                                    color: 'var(--clr-neutral-100)',
                                    ".expandIconWrapper": { color: 'var(--clr-neutral-100)' },
                                    marginLeft: '1rem',
                                }}
                            />}
                            aria-controls="panel-content"
                            sx={{
                                color: 'var(--clr-neutral-100)',
                                padding: '3rem',
                            }}
                        >Restoring trust and product market leadership during VC negotiations</AccordionSummary>
                        <AccordionDetails sx={{
                                fontSize: 'var(--fs-300)',
                                '@media screen and (min-width:600px)': {
                                    fontSize: 'var(--fs-600)',
                                    paddingLeft: '8rem',
                                    paddingRight: '8rem',
                                },
                            }}
                        >
                            <div className='case-header-image-container'>
                                <img className='case-header-image' src={RestoringTrust} alt="a grid of person icons with a magnifying glass centered around one person" />
                            </div>
                            <p><span className='case-section-subheader'>Challenge:</span> The client launched a new product in a competitive market, only to be hit with an FDA black-box warning within a year. This warning led to immediate and severe consequences.  Sales plummeted by 90% within a month due to the product's inpatient hospital usage. Hospital protocols generally restrict the administration of black-box warning products, requiring substantial medical justification. The product was crucial for a forthcoming VC acquisition. The sales decline threatened to derail the acquisition. The product, being new, lacked substantial data to support its safety and efficacy, undermining trust among healthcare providers and institutions.</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='case-section-subheader'>RareInsights™ : <span className='case-section-sub-subheader'>Trust and safety is everything</span></span> Our solution was transformative, focusing on reshaping market perception and rebuilding trust. We delved deep into the market and regulatory landscapes, not just to understand but to redefine how the product was viewed in light of the safety reports and ensuing product trust deficit. Collaborating with Key Opinion Leaders (KOLs), RW data was analyzed and used to humanize the narrative around the product’s safety, especially concerning patient comorbidities and specific clinical settings. Our RareInsights™, generated a trust-centric campaign, securing endorsements from influential users to bolster the product's credibility, and transforming the conversation around clinical benefits outweighing the risks when used appropriately.  The relaunch toolkit – encompassing formulary kits, market access strategies, and medical societies advocacy – served as a catalyst for industry change. The heart of RareVox’s methodology lies in collaboration. We brought together diverse internal stakeholders - from sales to clinical application specialists - each playing a critical role. This wasn’t just about team involvement; it was about creating a symphony of expertise, all harmonized towards a singular goal of reestablishing trust and market position.</p>
                            <hr className='case-subsection-divider'/>
                            <p><span className='case-section-subheader'>The impact</span> was significant. The VC acquisition deal proceeded successfully, underscoring the efficacy of our strategic response. The product not only recovered from its initial setback but also became the market leader, maintaining this position to date. The campaign effectively restored trust in the product among healthcare professionals and institutions.</p>
                        </AccordionDetails>
                </Accordion>
            </section>
        </main>
    );
}