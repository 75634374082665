import React from "react";
import { useForm, ValidationError } from '@formspree/react';

import '../assets/styles/Contact.css'

import Book from '../pages/Book';

import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'

export default function Contact() {
    const [state, handleSubmit] = useForm("xgegowpd");

    if (state.succeeded) {
        return <Book />;
    }

    return (
        <main>
            <img id='contact-section-divider' src={VocalDivider600} alt="" />
            <div id='form-container'>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">
                        Name
                    </label>
                    <input
                        id="name"
                        type="text" 
                        name="name"
                        placeholder="John Doe"
                        required
                    />
                    <label htmlFor="company">
                        Company
                    </label>
                    <input
                        id="company"
                        type="text" 
                        name="company"
                        placeholder="ACME Corp."
                    />
                    <label htmlFor="email">
                        Email Address
                    </label>
                    <input
                        id="email"
                        type="email" 
                        name="email"
                        placeholder="email@domain.com"
                        required
                    />
                    <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                    />
                    
                    <label htmlFor="phone">
                        Phone
                    </label>
                    <input
                        id="phone"
                        type="phone number" 
                        name="phone"
                        placeholder="123-456-7890"
                    />
                    <ValidationError 
                        prefix="Phone" 
                        field="phone"
                        errors={state.errors}
                    />
                    {/* <textarea
                        id="message"
                        name="message"
                    />
                    <ValidationError 
                        prefix="Message" 
                        field="message"
                        errors={state.errors}
                    /> */}
                    <button type="submit" disabled={state.submitting}>
                        Submit
                    </button>
                </form>
            </div>
        </main>
    )
}

