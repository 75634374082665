import React from 'react'

import '../assets/styles/ContactButton.css'

const ContactButton = () => {
    return (
        <div>
            <a href="/contact">
                <button id='contact-button'>CONTACT</button>
            </a>
        </div>
    )
}

export default ContactButton
