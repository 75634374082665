import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';

import '../assets/styles/CaseCarousel.css'

import WomanHeart from '../assets/graphics/woman-heart.png'
import TranformingPerceptions from '../assets/graphics/transforming-perceptions.png'
import RestoringTrust from '../assets/graphics/restoring-trust.png'


const CaseCarousel = () => {

  return (
        <div id='swiper-container'>
          <Swiper
            effect={'cards'}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img className='case-carousel-section-image' src={TranformingPerceptions} alt="black box with blurred graphic inside, a radiowave icon leading to a clear box with a value icon inside." />
              <h3 className='case-carousel-section-header'>Transforming Perceptions: A Success Story in Parkinson’s Line Extension</h3>
              <p className='case-carousel-section-text'><span className='case-carousel-section-text-summary'>Summary:</span> Pharma company challenge with their Parkinson’s disease line extension despite the novel drug delivery method. Recognizing the need for a patient-focused approach, we modified all messaging to a QOL-focused message. It proved transformative. Within 12 months, the new drug delivery method saw a 75% adoption rate among existing patients.  </p>
            </SwiperSlide>
            <SwiperSlide>
              <img className='case-carousel-section-image' src={WomanHeart} alt="a group of men and women with heart icons on their chest, men with men hearts and women with orange hearts." />
              <h3 className='case-carousel-section-header'>Niche to Mainstream: Catalyzing Broad Product Demand Through Targeted Patient Group</h3>
              <p className='case-carousel-section-text'><span className='case-carousel-section-text-summary'>Summary:</span> Radiopharma company grappling with launch and market penetration of a new, more sensitive and specific imaging agent.  We recognized an opportunity to shift the paradigm in cardiac imaging – focusing on the often-overlooked diagnostic journey of women. The impact was immediate and profound. </p>
            </SwiperSlide>
            <SwiperSlide>
              <img className='case-carousel-section-image' src={RestoringTrust} alt="a grid of person icons with a magnifying glass centered around one person" />
              <h3 className='case-carousel-section-header'>Restoring trust and product market leadership during VC negotiations</h3>
              <p className='case-carousel-section-text'><span className='case-carousel-section-text-summary'>Summary:</span> A diagnostic company launched successfully their product in a competitive space and within a year received a box-warning from FDA resulting in 90% revenue lost within 1 month.This situation was further complicated by the product's critical role in a pending venture capital (VC) acquisition deal. We jumped in to perform a complex  cross-functional situation analysis leading  to a series of recommendations and actions  to  restore confidence in the product, properly position it and re-launch successfully.</p>
            </SwiperSlide>
          </Swiper>
        </div>
      );
    }


export default CaseCarousel
