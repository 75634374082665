import React from 'react';

import '../assets/styles/HeroCarousel.css';

import ContactButton from './ContactButton';

const HeroCarousel = () => {
  return (
    <section id='hero-section'>
        <div id="hero-container">
            <div id='hero-content-container'>
                <div id='hero-text-container'>
                    <h2 className="hero-text">
                        Multi-Dimensional
                    </h2>
                    <h2 className="hero-text">
                        Patient-Centric
                    </h2>
                    <h2 className="hero-text">
                        Insight-Fueled
                    </h2>
                </div>
                <ContactButton/>
            </div>
        </div>
    </section>
  )
}

export default HeroCarousel
