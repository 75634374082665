import React from 'react'
import { Link } from 'react-router-dom'

import '../assets/styles/Home.css'

import HeroCarousel from '../components/HeroCarousel'
import CaseCarousel from '../components/CaseCarousel'

import Molecule from '../assets/images/molecule.png'

import VocalDivider400 from '../assets/graphics/vocal-divider-400.svg'
import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'

export default function Home() {
    return (
        <main>
            <HeroCarousel/>
            <img id='introduction-section-divider' src={VocalDivider400} alt="" />
            <section id='introduction-section'>
                <div id='introduction-section-content-container'>
                    <div id='introduction-section-content'>
                        <h2 id='introduction-section-header'>Why RareVox Consulting?</h2>
                        <h3 id='introduction-section-subheader'>Tailored Comprehensive Solutions</h3>
                        <p className='introduction-section-text'><span className='introduction-section-text-title'>Flexibility:</span> The ability to scale services up or down based on client requirements, ranging from full-scale strategic planning to specific operational tasks.</p>
                        <p className='introduction-section-text'><span className='introduction-section-text-title'>Customization:</span> Tailoring services to the unique challenges and objectives of each client, ensuring that both strategic goals and operational needs are met.</p>
                        <p className='introduction-section-text'><span className='introduction-section-text-title'>Integrated Approach:</span> Offering a seamless blend of strategic insight and practical execution, ensuring that strategies are not only developed but also effectively implemented.</p>
                    </div>
                </div>
            </section>
            <img id='case-section-divider' src={VocalDivider600} alt="" />
            <section id='case-section'>
                <CaseCarousel/>
                <div id='case-carousel-section-link-section'>
                    <p><Link to="/cases" id='case-carousel-section-link'>Read more...</Link></p>
                </div>
            </section>
        </main>
    )
}