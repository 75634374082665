export const MenuItems = [
    {
      title: 'Stategic Consultancy',
      path: '/strategicconsultancy',
      cName: 'dropdown-link'
    },
    {
      title: 'Operational Support',
      path: '/operationalsupport',
      cName: 'dropdown-link'
    }
  ];