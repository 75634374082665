import React, { useState } from 'react';
// import { Route, Router, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';


import Home from './pages/Home';
import Book from './pages/Book';
import Contact from './pages/Contact';
import StrategicConsultancy from './pages/StrategicConsultancy';
import OperationalSupport from './pages/OperationalSupport';
import About from './pages/About';
import Cases from './pages/Cases';

import './assets/styles/Styles.css';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book" element={<Book />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/strategicconsultancy" element={<StrategicConsultancy />} />
          <Route path="/operationalsupport" element={<OperationalSupport />} />
          <Route path="/about" element={<About />} />
          <Route path="/cases" element={<Cases />} />
      </Routes>
      <Footer />
  </div>
  );
}

export default App;
