import React from 'react'

import '../assets/styles/OperationalSupport.css'

import VocalDivider100 from '../assets/graphics/vocal-divider-100.svg'
import VocalDivider400 from '../assets/graphics/vocal-divider-400.svg'
import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'
import VocalDivider800 from '../assets/graphics/vocal-divider-800.svg'

import Presentation from '../assets/graphics/presentation.png'
import SocialMedia from '../assets/graphics/social-media.png'
import Machine from '../assets/graphics/machine.png'

export default function OperationalSupport() {
    return (
        <main>
            <section id='hero-section-operational-support'>
                <h3 id="hero-title-operational-support">Operational Support</h3>
            </section>
            <img id='advisory-section-divider' src={VocalDivider400} alt="" />
            <section id='advisory-section'>
                <div id='advisory-section-container'>
                    <div id='advisory-section-case-container'>
                        <h3 id='advisory-section-header'>Advisory and Representation Services: </h3>
                        <p id='advisory-section-text'>Providing expert representation at industry events, advisory board management and hosting, and medical conferences attendance and reporting.</p>
                        <img id='advisory-section-image' src={Presentation} alt="" />
                    </div>
                </div>
            </section>
            <img id='tactical-implementation-section-divider' src={VocalDivider600} alt="" />
            <section id='tactical-implementation-section'>
                <div id='tactical-implementation-section-container'>
                    <div id='tactical-implementation-section-case-container'>
                        <h3 id='tactical-implementation-section-header'>Tactical Implementation:</h3>
                        <div id='tactical-implementation-section-content-container'>
                            <img id='tactical-implementation-section-image' src={SocialMedia} alt="" />
                            <p id='tactical-implementation-section-text'>Supporting implementation of established marketing tactical plans, including omnichannel and digital campaigns. Update and refresh content for field organization, web presence, and more.</p>
                        </div>
                    </div>
                </div>
            </section>
            <img id='ops-section-divider' src={VocalDivider800} alt="" />
            <section id='ops-section'>
                <div id='ops-section-container'>
                    <div id='ops-section-case-container'>
                        <h3 id='ops-section-header'>Marketing Operations Support:</h3>
                        <p id='ops-section-text'>Providing support with marketing communications, vendor management, and specific project or expanding in-house capabilities for a defined period of time.</p>
                        <img id='ops-section-image' src={Machine} alt="" />
                    </div>
                </div>
            </section>
        </main>
    )
}