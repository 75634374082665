import React from 'react';
import '../assets/styles/MenuButton.css';

const MenuButton = () => {
  return (
    <div>
        <a href="/contact">
            <button id='menu-button'>CONTACT</button>
        </a>
    </div>
  );
}

export default MenuButton