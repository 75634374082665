import React from 'react'

import '../assets/styles/StrategicConsultancy.css'

import VocalDivider100 from '../assets/graphics/vocal-divider-100.svg'
import VocalDivider400 from '../assets/graphics/vocal-divider-400.svg'
import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'
import VocalDivider800 from '../assets/graphics/vocal-divider-800.svg'

import Strategy from '../assets/graphics/strategy.png'
import Path from '../assets/graphics/path.png'
import Handshake from '../assets/graphics/handshake.png'

export default function StrategicConsultancy() {
    return (
        <div>
            <section id='hero-section-strategic-consultancy'>
                <h3 id="hero-title-strategic-consultancy">Strategic Consultancy</h3>
            </section>
            <img id='strategic-integration-section-divider' src={VocalDivider400} alt="" />
            <section id='strategic-integration-section'>
                <div id='strategic-integration-section-container'>
                    <div id='strategic-integration-section-case-container'>
                        <h3 id='strategic-integration-section-header'>Market Integration for Radiopharmaceuticals & Specialty Pharmaceuticals: </h3>
                        <div id='strategic-integration-section-content-container'>
                            <img id='strategic-integration-section-image' src={Strategy} alt="" />
                            <p id='strategic-integration-section-text'>We define market segmentation,  healthcare transaction models and decision points in complex ecosystems. We review the target product profile and minimum acceptable profile. We create strategies to capture the optimal value of the asset leveraging our experience in global marketing strategy and lifecycle management. </p>
                        </div>
                    </div>
                </div>
            </section>
            <img id='advocacy-section-divider' src={VocalDivider600} alt="" />
            <section id='advocacy-section'>
                <div id='advocacy-section-container'>
                    <div id='advocacy-section-case-container'>
                        <h3 id='advocacy-section-header'>Patient Journey Mapping/Advocacy:</h3>
                        <p id='advocacy-section-text'>We design comprehensive patient journey mapping from pre-diagnosis to treatment and beyond. We will liaise with medical societies and patient advocacy groups to capture the essence of their needs and articulate how your product meet those needs. The insightful messaging and the targeted marketing will drive product utilization and acceptance.</p>
                        <img id='advocacy-section-image' src={Path} alt="" />
                    </div>
                </div>
            </section>
            <img id='strategic-alliance-section-divider' src={VocalDivider800} alt="" />
            <section id='strategic-alliance-section'>
                <div id='strategic-alliance-section-container'>
                    <div id='strategic-alliance-section-case-container'>
                        <h3 id='strategic-alliance-section-header'>Strategic Alliance and Partnership Support:  </h3>
                        <div id='strategic-alliance-section-content-container'>
                            <img id='strategic-alliance-section-image' src={Handshake} alt="" />
                            <p id='strategic-alliance-section-text'>Drawing on our experience in business integration and alliance management, we will facilitate the transition between smaller and larger entities during mergers, alliances or joint marketing programs, helping the partners navigate the complexities of the radiopharmaceutical market and product launch in this unique space.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}