import React from 'react';

import '../assets/styles/About.css'

import VocalDivider100 from '../assets/graphics/vocal-divider-100.svg'
import VocalDivider400 from '../assets/graphics/vocal-divider-400.svg'
import VocalDivider600 from '../assets/graphics/vocal-divider-600.svg'
import VocalDivider800 from '../assets/graphics/vocal-divider-800.svg'

import Virgile from '../assets/images/people/virgile.png'
import Suzanne from '../assets/images/people/suzanne.png'
import Celia from '../assets/images/people/celia.png'

export default function About() {
    return (
        <main>
            <section id='about-hero-section'>
                <h3 id="about-hero-title">About Us</h3>
            </section>
            <img id='motto-section-divider' src={VocalDivider100} alt="" />
            <section id='motto-section'>
                <p id='motto-section-text'>At <span id='motto-section-highlight'>RareVox Consulting</span>, we believe in creating more than just strategies; we craft success stories.</p>
            </section>
            <img id='philosophy-section-divider' src={VocalDivider400} alt="" />
            <section id='philosophy-section'>
                <div id='philosophy-section-content'>
                    <h3 id='philosophy-section-header'>Our Philosophy</h3>
                    <p className='philosophy-section-text'>Deeply rooted in the understanding that successful strategies are multi-dimensional, weaving together the intricate threads of your business, the dynamic landscape of the market, and, most importantly, the needs and benefits of the patients.</p>
                    <p className='philosophy-section-text'>We don't just consult; we become a part of your journey, walking alongside you to bridge the crucial gaps between patients, clinicians, and your innovative products and services.</p>
                </div>
            </section>
            <img id='name-section-divider' src={VocalDivider600} alt="" />
            <section id='name-section'>
                <div id='name-section-content'>
                    <h3 id='name-section-header'>Our Name</h3>
                    <p className='name-section-text'><span className='white-highlight'>RareVox</span>, evokes uniqueness and specialization. 'Rare' signifies the distinct nature of our expertise, while 'Vox', a nod to both the Latin root for 'Voice' and 'Voxel', symbolizes our commitment to giving a voice to your vision and integrating it into a multidimensional strategic framework. At the heart of our ethos is a patient-centric approach, ensuring that every strategy we develop not only advances your business objectives but also profoundly impacts patient care and outcomes.</p>
                    <p className='name-section-text'>With <span className='white-highlight'>RareVox Consulting</span>, you gain more than a consultant; you gain a partner dedicated to advancing your path in the radiopharmaceutical and speciality pharmaceutical landscapes with <span className='white-highlight'>RareInsigths™</span> ensuring that your innovations reach their full potential and make a meaningful difference in the life of the patients we serve.</p>
                </div>
            </section>
            <img id='people-section-divider' src={VocalDivider800} alt="" />
            <section id='people-section'>
                <div id='people-section-content'>
                    <div className='person-container' id='suzanne-container'>
                        <img className='person-image' src={Suzanne} alt='Picture of Suzanne'/>
                        <div className='person-text-container'>
                            <p className='person-name'>Suzanne</p>
                            <p className='person-title'>head of marketing</p>
                        </div>
                    </div>
                    <div className='person-container' id='virgile-container'>
                        <img className='person-image' src={Virgile} alt='Picture of Virgile'/>
                        <div className='person-text-container'>
                            <p className='person-name'>Virgile</p>
                            <p className='person-title'>head of technology</p>
                        </div>
                    </div>
                    <div className='person-container' id='virgile-container'>
                        <img className='person-image' src={Celia} alt='Picture of Celia'/>
                        <div className='person-text-container'>
                            <p className='person-name'>Celia</p>
                            <p className='person-title'>head of operations</p>
                        </div>
                    </div>
                    <div className='person-container' id='virgile-container'>
                        <img className='person-image' src={Virgile} alt='Picture of Virgile'/>
                        <div className='person-text-container'>
                            <p className='person-name'>Geoff</p>
                            <p className='person-title'>administrative assistant</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}